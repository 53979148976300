import React from "react"
import './app.css'
import {useSelector} from "react-redux";
import DashboardView from "./Dashboard/DashboardView";
import LoginView from "./Login/LoginView";

const App = () => {
  const isLoggedIn = useSelector( state => state.user.isLoggedIn)
  // check for existing token in redux and display dashboard
  if (isLoggedIn) {
    return <DashboardView />
  }

  return <LoginView />

}

export default App;
