import React, {useState} from "react"
import {Input} from "@material-ui/core";
import {StartPasswordResetFlow} from "../actions";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Post from "../http";
import LoadingButton from "../utils/LoadingButton";

const EmailEntry = () => {
    const [errors, setErrors] = useState("")
    const [username, setUsername] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const clickStart = () => {

        // sanity check for x@y.z
        const re = /\S+@\S+\.\S+/
        if (!re.test(username)) {
            setErrors(t("login.forgotPassword.invalidEmailError"))
            return
        }

        // update UI
        setIsLoading(true)
        setButtonDisabled(true)

        // send username to API
        const methodsParams = {
            path: "/get-methods",
            data: {
                username: username
            }
        }

        Post(methodsParams).then( (response) => {
            if (response.success) {
                // set data into redux and display next view
                if (Object.keys(response.data).length > 0) {
                    dispatch(StartPasswordResetFlow(username, response.data))
                    return
                }
            }

            // something is borked, display error to user
            setErrors(t("utils.generalErrors.vagueError"))
        }).catch( reason => {
            // same UX actually
            setErrors(t("utils.generalErrors.vagueError"))
        }).finally( () => {
            setButtonDisabled(false)
            setIsLoading(false)
        })
    }

    const changeUsername = (evt) => {
        setErrors("")
        setButtonDisabled(evt.target.value === "")
        setUsername(evt.target.value)
    }

    return (
        <div className="login-view">
            {errors !== "" && <div className="login-errors">{errors}</div>}

            <div className="login-copy">
                {t("login.forgotPassword.description")}
            </div>

            <Input className="login-input"
                   type="text"
                   value={username}
                   onChange={changeUsername}
                   placeholder={t("login.forgotPassword.usernameLabel")} />

            <LoadingButton label={t("login.forgotPassword.startButtonLabel")} isDisabled={buttonDisabled} isLoading={isLoading} onClick={clickStart} />
        </div>
    )
}


export default EmailEntry