import React, {useEffect, useState} from "react"
import {validatePasswords} from '../utils/passwordValidation'
import {useTranslation} from "react-i18next"
import PasswordValidationDisplay from "../utils/PasswordValidationDisplay";
import {Input} from "@material-ui/core";
import LoadingButton from "../utils/LoadingButton";
import Post from "../http";
import {PasswordUpdated, SetErrors} from "../actions";
import {useDispatch} from "react-redux";

const initialValidationState = validatePasswords("", "")

const PasswordCreation = () => {
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [validation, setValidation] = useState(initialValidationState)
    const [validated, setValidated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    // validate when either password value changes
    useEffect( () => {
        let newValidationValues = validatePasswords(password1, password2)
        setValidated(newValidationValues.results.fullyValidated)
        setButtonDisabled(!newValidationValues.results.fullyValidated)
        setValidation(newValidationValues)
    }, [password1, password2])

    const changePassword1 = (evt) => {
        setPassword1(evt.target.value)

    }
    const changePassword2 = (evt) => {
        setPassword2(evt.target.value)
    }

    const clickSave = () => {
        setButtonDisabled(true)
        setIsLoading(true)

        // send new password to backend
        let params = {
            path: "/reset-password",
            data: {
                new_password: password1,
                access_token: sessionStorage.getItem('access_token')
            }
        }
        Post(params).then(response => {
            if (response.success) {
                // password change was accepted, we're already logged in so just redirect to dashboard
                dispatch(PasswordUpdated())
            } else {
                dispatch(SetErrors(t("utils.generalErrors.passwordNotAccepted")))
            }
        }).catch( reason => {
            dispatch(SetErrors(t("utils.generalErrors.serverError")))
        })

    }

    return (
        <div className="login-view">
            <div className="login-copy">
                {t("login.createPassword.introText")}
            </div>

            <PasswordValidationDisplay validationRules={validation.rules} validated={validated} />

            <Input className="login-input"
                   type="text"
                   value={password1}
                   onChange={changePassword1}
                   placeholder={t("login.createPassword.password1Label")} />

            <Input className="login-input"
                   type="text"
                   value={password2}
                   onChange={changePassword2}
                   placeholder={t("login.createPassword.password2Label")} />

            <LoadingButton onClick={clickSave} isLoading={isLoading} isDisabled={buttonDisabled} label={t("login.createPassword.saveButtonLabel")} />

        </div>
    )
}

export default PasswordCreation