import store from './store'
import {DateTime} from "luxon";

async function getHeaderToken(baseURI) {
    // fetch token and expiration from sessionStorage
    const accessToken = sessionStorage.getItem('access_token')
    if (accessToken !== null) {
        const now = DateTime.utc()
        const accessTokenExpiration = sessionStorage.getItem('access_token_expires_at')
        const refreshToken = sessionStorage.getItem('refresh_token')
        const refreshTokenExpiration = sessionStorage.getItem('refresh_token_expires_at')
        if (accessTokenExpiration === null || refreshToken === null || refreshTokenExpiration === null) {
            return false
        }

        // if access token hasn't expired, we can use that
        const accessExpires = DateTime.fromISO(accessTokenExpiration, {zone: "UTC"})
        if (accessExpires > now) {
            return accessToken
        }

        // otherwise we can check the refresh expiration
        const refreshExpires = DateTime.fromISO(refreshTokenExpiration, {zone: "UTC"})
        if (refreshExpires > now) {
            // refresh token is not expired, use it to get a new set
            let refreshOptions = {
                method: "POST",
                cache: "no-cache",
                headers: {'Content-Type': "application/json"}
            }
            refreshOptions.body = JSON.stringify({refresh_token: refreshToken, access_token: accessToken})
            let response = await fetch(baseURI + "/refresh-token", refreshOptions).json()
            if (response.success && response.data !== undefined) {
                // set new token data into sessionStorage and return access token
                sessionStorage.setItem("access_token", response.data.access_token)
                sessionStorage.setItem("access_token_expires_at", response.data.access_token_expires_at)
                sessionStorage.setItem("refresh_token", response.data.refresh_token)
                sessionStorage.setItem("refresh_token_expires_at", response.data.refresh_token_expires_at)
                return response.data.access_token
            }
        }

    }
    return false
}

async function Post(params) {
    const redux = store.getState()

    if (params.path === undefined || params.data === undefined) {
        return Promise.reject("Malformed Params")
    }
    document.cookie = "username=John Doe; expires=Thu, 26 Aug 2021 12:00:00 UTC; path=/";

    // set content-type header
    let headers = {
        'Content-Type': "application/json",
    }

    // auth token comes from sessionStorage
    let accessToken = await getHeaderToken(redux.config.apiURL)
    if (accessToken) {
        headers.Authorization = accessToken
    }

    // build init options object for fetch
    let requestOptions = {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers
    }

    const payload = params.data

    requestOptions.body = JSON.stringify(payload)

    // fetch response and return the json result
    const fetchURI = redux.config.apiURL + params.path
    const response = await fetch(fetchURI, requestOptions)

    // make sure we got a JSON response
    const responseContentType = response.headers.get('content-type')
    if (!responseContentType || !responseContentType.includes('application/json')) {
        return Promise.reject({
            code: 501,
            message: "Malformed response",
            responseStatus: response.status
        })
    }

    return response.json()
}


export default Post