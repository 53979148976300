const initialState = {
    user: {
        username: undefined,
        user_id: undefined,
        isLoggedIn: false,
        deliveryMethods: undefined,
        chosenDeliveryMethod: undefined,
    },
    config: {},
    view: "login",
    flow: "login",
    loginError: false
}


export default function reducer(state = initialState, action) {
    let newUser = undefined

    switch (action.type) {

        case "CONFIGURE":
            return { ...state, config: action.payload }

        case "LOGIN.VIEW":
            return { ...state, view: action.payload }


        case "LOGIN.SET_ERRORS":
            return { ...state, loginError: action.payload, view: "credential" }

        case "LOGIN.CLEAR_ERRORS":
            return { ...state, loginError: false }

        case "LOGIN.PASSWORD_RESET.START":
            return {
                ...state,
                user: {
                    username: action.payload.username,
                    user_id: undefined,
                    isLoggedIn: false,
                    deliveryMethods: action.payload.deliveryMethods,
                    chosenDeliveryMethod: undefined,
                },
                view: "delivery-methods",
                flow: "password-reset"
            }

        case "LOGIN.PASSWORD_RESET.VERIFIED":
            return {
                ...state,
                view: "create-password"
            }

        case "LOGIN.PASSWORD_RESET.UPDATED":
            return {
                ...state,
                view: "password-created"
            }

        case "LOGIN.MFA.START":
            return {
                ...state,
                user: {
                    ...state.user,
                    username: action.payload.username,
                    isLoggedIn: false,
                    deliveryMethods: action.payload.deliveryMethods
                },
                flow: "MFA",
                view: "delivery-methods"
            }

        case "LOGIN.MFA.SUCCESS":
            return {
                ...state,
                user: {
                    ...state.user,
                    isLoggedIn: true
                },
                view: "dashboard"
            }

        case "LOGIN.CODE_SENT":
            return {
                ...state,
                user: {
                    ...state.user,
                    user_id: action.payload.userID,
                    chosenDeliveryMethod: action.payload.chosenMethod
                },
                view: "code-entry"
            }

        case "LOGIN.DELIVERY_METHODS":
            newUser = {...state.user, deliveryMethods: action.payload}
            return { ...state, user: newUser }

        case "LOGIN.SUCCESS":
            newUser = {...state.user, isLoggedIn: true}
            return {
                ...state,
                user: newUser,
                view: "dashboard"
            }

        default:
            return state
    }
}