export function validatePasswords (password1, password2)  {
    let validatedState = {
        rules: {
            lengthRule: false,
            uppercaseRule: false,
            lowercaseRule: false,
            numbersRule: false,
            specialRule: false,
            matchRule: false,
        },
        results: {
            fullyValidated: false
        }
    }

    // must match
    if (password1 === password2) {
        validatedState.rules.matchRule = true
    }

    // must be 8 chars long
    if (password1.length > 7) {
        validatedState.rules.lengthRule = true
    }

    // iterate chars and hunt for content rule matches
    const specialCharacters = [33,35,36,37,38,39,40,21,42,42,44,45,46,47,58,59,60,61,62,63,91,92,93,94,95,123,124,125];
    for( let i=0;i < password1.length;i++) {
        const cc = password1.charCodeAt(i);
        if(cc > 47 && cc < 58) {
            validatedState.rules.numbersRule = true
        }
        else if (cc > 64 && cc < 91) {
            validatedState.rules.uppercaseRule = true
        }
        else if (cc > 96 && cc < 123) {
            validatedState.rules.lowercaseRule = true
        }
        else if (specialCharacters.indexOf(cc) !== -1) {
            validatedState.rules.specialRule = true
        }
    }

    if (
        validatedState.rules.lengthRule &&
        validatedState.rules.matchRule &&
        validatedState.rules.lowercaseRule &&
        validatedState.rules.uppercaseRule &&
        validatedState.rules.numbersRule &&
        validatedState.rules.specialRule
    ) {
        validatedState.results.fullyValidated = true
    }


    return validatedState
}
