export function setAccessToken(accessToken, tokenExpiration) {
    sessionStorage.setItem("access_token", accessToken)
    sessionStorage.setItem("access_token_expires_at", tokenExpiration)
}

export function setRefreshToken(refreshToken, tokenExpiration) {
    sessionStorage.setItem("refresh_token", refreshToken)
    sessionStorage.setItem("refresh_token_expires_at", tokenExpiration)
}

export function setMFAToken(mfaToken, tokenExpiration) {
    localStorage.setItem("mfa_token", mfaToken)
    localStorage.setItem("mfa_token_expires_at", tokenExpiration)
}