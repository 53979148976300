import React, {useState} from "react"
import {Button, FormControl, Input, InputAdornment} from "@material-ui/core"
import AccountCircle from "@material-ui/icons/AccountCircle"
import Lock from "@material-ui/icons/Lock"
import {useTranslation} from "react-i18next";
import {ClearErrors, LoggedIn, SetErrors, SetLoginView, StartMFAFlow} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import Post from "../http";
import LoadingButton from "../utils/LoadingButton";
import {setAccessToken, setRefreshToken} from "../utils/tokenManagement";

const CredentialEntry = () => {
    const [password, setPassword] = useState("")
    const [username, setUsername] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const errors = useSelector(state => state.loginError)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const clickLogin  = () => {
        setIsLoading(true)
        setButtonDisabled(true)

        // get MFA token from localStorage
        let mfaToken = localStorage.getItem('mfa_token')
        if (!mfaToken) mfaToken = ""

        // build login payload
        let payload = {
            path: "/login",
            data: {
                user_name: username,
                password: password,
                token_mfa: mfaToken
            }
        }

        Post(payload).then( response => {
            if (response.success) {
                // login was successful and response contains tokens
                setAccessToken(response.data.access_token, response.data.access_token_expires_at)
                setRefreshToken(response.data.refresh_token, response.data.refresh_token_expires_at)
                dispatch(LoggedIn(response.data.user_id, response.data.username))
            } else if (response.error_code === undefined) {
                // no errorcode means MFA is required and response contains delivery method data
                if (response.data !== undefined && response.data.result === "mfa") {
                    let methods = {}
                    if (response.data.sms !== undefined) {
                        methods.sms = response.data.sms
                    }
                    if (response.data.email !== undefined) {
                        methods.email = response.data.email
                    }

                    dispatch(StartMFAFlow(username, methods))
                }

            } else {
                setIsLoading(false)

                // the errorcode will determine what to display to the user
                switch(response.error_code) {
                    case 401:
                    case 429:
                        let errorMessage = t("utils.generalErrors.loginFail")
                        errorMessage.replace("$attempts", response.data.remaining_attempts)
                        dispatch(SetErrors(errorMessage))
                        break
                    default:
                        dispatch(SetErrors(t("utils.generalErrors.serverError")))
                }
            }
        }).catch( error => {
            console.log("General http error: ", error)
            dispatch(SetErrors(t("utils.generalErrors.serverError")))
        })
    }

    const clickPasswordForgot = () => {
        dispatch(SetLoginView("forgot-email"))
    }

    const changeUsername = (evt) => {
        setButtonDisabled(evt.target.value === "" || password === "")
        setUsername(evt.target.value)
        if (errors) dispatch(ClearErrors())
    }

    const changePassword = (evt) => {
        setButtonDisabled(evt.target.value === "" || username === "")
        setPassword(evt.target.value)
        if (errors) dispatch(ClearErrors())
    }

    return (
        <div className="login-view">
            <div className="login-copy">
                {t("login.credentials.introText")}
            </div>
            {errors !== "" && <div className="login-errors">{errors}</div>}

            <div className="login-input">
                <FormControl className="full-width">
                    <Input value={username}
                           type="text"
                           placeholder={t("login.credentials.usernameLabel")}
                           onChange={changeUsername}
                           startAdornment={
                               <InputAdornment position="start"><AccountCircle /></InputAdornment>
                           }
                    />
                </FormControl>
            </div>
            <div className="login-input">
                <FormControl className="full-width">
                    <Input value={password}
                           type="password"
                           placeholder={t("login.credentials.passwordLabel")}
                           onChange={changePassword}
                           startAdornment={
                               <InputAdornment position="start"><Lock /></InputAdornment>
                           }
                    />
                </FormControl>
            </div>
            <LoadingButton onClick={clickLogin} label={t("login.credentials.loginButtonLabel")} isDisabled={buttonDisabled} isLoading={isLoading} />

            <Button onClick={clickPasswordForgot} size={"small"}>{t("login.credentials.forgotPasswordButtonLabel")}</Button>

            <div className="login-disclaimer">
                {t("login.credentials.disclaimerText")}
            </div>

        </div>
    )
}

export default CredentialEntry