import React, {useState} from "react"
import {useTranslation} from "react-i18next";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import LoadingButton from "../utils/LoadingButton";
import {useDispatch, useSelector} from "react-redux";
import Post from "../http";
import {CodeSent} from "../actions";

const DeliveryMethodSelect = () => {
    const methodsAvailable = useSelector(state => state.user.deliveryMethods)
    const [chosenMethod, setChosenMethod] = useState("")
    const [errors, setErrors] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const username = useSelector(state => state.user.username)
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const updateChosenMethod = (evt) => {
        if (evt.target.value.length > 0) setButtonDisabled(false)
        setChosenMethod(evt.target.value)
    }

    const clickSend = () => {
        // set button state to indicate we're handling the user action
        setButtonDisabled(true)
        setIsLoading(true)

        // hit API to have an OTU sent via chosenMethod
        let params = {
            path: "/send-code",
            data: {
                chosen_method: chosenMethod,
                username: username
            }
        }

        Post(params).then( response => {
            if (response.success) {
                // code was sent, response has user_id for verifying code
                dispatch(CodeSent(response.data.user_id, chosenMethod))
            } else {
                setErrors(t("utils.generalErrors.vagueError"))
            }
        }).catch( error => {
            setErrors(t("utils.generalErrors.vagueError"))

        }).finally( () => {
            setButtonDisabled(false)
            setIsLoading(false)
        })
    }

    if (methodsAvailable === undefined) {
        return ""
    } else {
        let emailMethod = null
        let textMethod = null
        if (methodsAvailable.sms !== undefined) {
            const textLabel = t('login.deliveryMethods.smsMethodLabel') + " " + methodsAvailable.sms
            textMethod = <FormControlLabel value="sms" control={<Radio color="default" />} label={textLabel} />
        }
        if (methodsAvailable.email !== undefined) {
            const emailLabel = t('login.deliveryMethods.emailMethodLabel') + " " + methodsAvailable.email
            emailMethod = <FormControlLabel value="email" control={<Radio color="default" />} label={emailLabel} />
        }
        return (
            <div className="login-view">
                <div className="login-copy">
                    {t("login.deliveryMethods.introText")}
                </div>
                {errors !== "" && <div className="login-errors">{errors}</div>}
                <div className="login-copy">
                    {t("login.deliveryMethods.deliveryQuestionText")}
                </div>

                <div className="login-input">
                    <RadioGroup aria-label="Delivery method" value={chosenMethod} onChange={updateChosenMethod}>
                        {textMethod}
                        {emailMethod}
                    </RadioGroup>
                </div>


                <LoadingButton onClick={clickSend} isLoading={isLoading} isDisabled={buttonDisabled} label={t("login.deliveryMethods.sendButtonLabel")} />

            </div>
        )
    }

}

export default DeliveryMethodSelect