import React from 'react'
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {CheckCircleOutline, HighlightOff} from "@material-ui/icons";
import {useTranslation} from "react-i18next";

const PasswordValidationDisplay = ({validationRules, validated}) => {
    const {t} = useTranslation()

    const generateListItems = () => {
        return Object.entries(validationRules).map( ([key, value], index) => {
            const translationKey = "utils.passwordValidation." + key
            return (
                <ListItem key={index}>
                    <ListItemIcon>
                        {value ? <CheckCircleOutline /> : <HighlightOff />}
                    </ListItemIcon>
                    <ListItemText primary={t(translationKey)} />
                </ListItem>
            )
        })
    }

    return (
        <div className="login-copy">
            <List dense className={"password-rules" + (validated ? " password-valid" : "")}>
                {generateListItems()}
            </List>
        </div>

    )
}

export default PasswordValidationDisplay