import i18n from 'i18next'
import { initReactI18next} from "react-i18next"
import en_US from "./localization/en_US"
import es_MX from "./localization/es_MX"

i18n.use(initReactI18next).init({
    debug: true,
    fallbackLng: code =>'en',
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            translation: en_US
        },
        es: {
            translation: es_MX
        }
    }
})

export default i18n