const en_US = {
    login: {
        credentials: {
            introText: "Log in to access the dashboard",
            usernameLabel: "Username",
            passwordLabel: "Password",
            loginButtonLabel: "Login",
            loginButtonWorking: "Working...",
            forgotPasswordButtonLabel: "Forgot Password?",
            disclaimerText: "This information-processing facility is owned and operated by 83Bar and unauthorized access is not permitted. Any user who continues the log-on process explicitly confirms they are authorized to access it and accepts that the disciplinary policy will be invoked in all instances of abuse of organizational facilities."
        },
        forgotPassword: {
            description: "Enter your username to get started.  It's usually the email address on your account.",
            invalidEmailError: "Please enter a valid email address.",
            usernameLabel: "Username",
            startButtonLabel: "Start",
            startButtonWorking: "Working..."
        },
        deliveryMethods: {
            introText: "For your protection we need to confirm your identity by sending you a one time security code.",
            deliveryQuestionText: "How would you like to receive your code?",
            emailMethodLabel: "Email to",
            smsMethodLabel: "Text message to",
            sendButtonLabel: "Send",
            sendButtonWorking: "Sending..."
        },
        codeEntry: {
            introText: "You should receive your security code via {deliveryMethod}. Security codes are good for only 5 minutes. " +
                "If you don’t receive it in the next minute or so, use the button below to send another one.",
            inputLabel: "Please enter your security code:",
            rememberMeLabel: "Remember me so I can skip the security code step for 30 days",
            rememberMeNote: "Note: do not select if this is a public or shared device.",
            continueButtonLabel: "Continue",
            continueButtonWorking: "Working...",
            resendButtonLabel: "Resend here",
        },
        createPassword: {
            introText: "Please enter a new password. Your new password must contain at least:",
            password1Label: "New Password",
            password2Label: "New Password Again",
            saveButtonLabel: "Save",
            saveButtonWorking: "Saving...",
            successMessage: "Your password has been updated, you can now login with your new password.",
            successLabel: "Login"
        }
    },
    utils: {
        generalErrors: {
            vagueError: "Something went wrong.  Please try again.",
            serverError: "A problem has occurred. Please try again.",
            loginFail: "Invalid username or password.  You have $attempts remaining attempts.",
            lockout: "Your IP has been locked due to excessive failed attempts. Please try again later.",
            incorrectOTP: "Incorrect security code entered. Please try again.",
            passwordNotAccepted: "Your password could not be reset. Please try again."
        },
        passwordValidation: {
            lengthRule: "8 characters total",
            uppercaseRule: "1 upper-case letter",
            lowercaseRule: "1 lower-case letter",
            numbersRule: "one number",
            specialRule: "one special character",
            matchRule: "passwords must match"
        }
    }
}

export default en_US