const en_US = {
    login: {
        credentials: {
            introText: "Inicie sesión para acceder al panel",
            usernameLabel: "Nombre de usuario",
            passwordLabel: "Contraseña",
            loginButtonLabel: "Iniciar sesión",
            loginButtonWorking: "Trabajando...",
            forgotPasswordButtonLabel: "¿Olvidó la contraseña?",
            disclaimerText: "Esta instalación de procesamiento de información es propiedad y está operada por 83Bar y no se permite el acceso no autorizado. Cualquier usuario que continúe con el proceso de inicio de sesión confirma explícitamente que está autorizado para acceder a él y acepta que la política disciplinaria se invocará en todos los casos de abuso de las instalaciones de la organización."
        },
        forgotPassword: {
            description: "Ingrese su nombre de usuario para comenzar. Por lo general, es la dirección de correo electrónico de su cuenta.",
            usernameLabel: "Nombre de usuario",
            startButtonLabel: "Comienzo",
            startButtonWorking: "Trabajando..."
        },
        deliveryMethods: {
            introText: "Para su protección, necesitamos confirmar su identidad enviándole un código de seguridad único.",
            deliveryQuestionText: "¿Cómo le gustaría recibir su código?",
            emailMethodLabel: "Email a",
            smsMethodLabel: "Mensaje de texto a",
            sendButtonLabel: "Envíalo",
            sendButtonWorking: "Enviando..."
        },
        codeEntry: {
            introText: "Debería recibir su código de seguridad a través de {deliveryMethod}. Los códigos de seguridad son válidos por solo 5 minutos. Si no lo recibe en el próximo minuto, use el botón de abajo para enviar otro.",
            inputLabel: "Ingrese su código de seguridad:",
            rememberMeLabel: "Recuérdame para que pueda omitir el paso del código de seguridad durante 30 días",
            rememberMeNote: "Nota: no seleccione si se trata de un dispositivo público o compartido.",
            continueButtonLabel: "Continuar",
            continueButtonWorking: "Trabajando...",
            resendButtonLabel: "Reenviar aquí",
        }

    }
}

export default en_US