
export function SetLoginView(targetView) {
    return {
        type: "LOGIN.VIEW",
        payload: targetView
    }
}

export function LoggedIn() {
    return {
        type: "LOGIN.SUCCESS"
    }
}


export function StartPasswordResetFlow(username, deliveryMethods) {
    return {
        type: "LOGIN.PASSWORD_RESET.START",
        payload: {
            username,
            deliveryMethods
        }
    }
}

export function StartMFAFlow(username, deliveryMethods) {
    return {
        type: "LOGIN.MFA.START",
        payload: {
            username,
            deliveryMethods
        }
    }
}

export function CodeSent(userID, chosenMethod) {
    return {
        type: "LOGIN.CODE_SENT",
        payload: {
            userID,
            chosenMethod,
        }
    }
}

export function MFASuccess() {
    return {
        type: "LOGIN.MFA.SUCCESS"
    }
}

export function PasswordResetVerified() {
    return {
        type: "LOGIN.PASSWORD_RESET.VERIFIED"
    }
}

export function PasswordUpdated() {
    return {
        type: "LOGIN.PASSWORD_RESET.UPDATED"
    }
}

export function SetErrors(errorString) {
    return {
        type: "LOGIN.SET_ERRORS",
        payload: errorString
    }
}

export function ClearErrors() {
    return {
        type: "LOGIN.CLEAR_ERRORS"
    }
}
