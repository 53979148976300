import React from "react"
import {
    Container,
    Paper,
} from "@material-ui/core"
import Logo from '../images/83Bar-white.png'
import {useSelector} from "react-redux"
import CredentialEntry from "./CredentialEntry"
import EmailEntry from "./EmailEntry"
import DeliveryMethodSelect from "./DeliveryMethodSelect"
import PasswordCreation from "./PasswordCreation"
import PasswordCreated from "./PasswordCreated";
import CodeEntry from "./CodeEntry";

const LoginView = () => {
    const loginView = useSelector( state => state.view)


    const loginRouter = () => {
        switch(loginView) {
            case "credential":
                return <CredentialEntry />

            case "forgot-email":
                return <EmailEntry />

            case "delivery-methods":
                return <DeliveryMethodSelect />

            case "code-entry":
                return <CodeEntry />

            case "code-entry-2fa":
                return <CodeEntry />

            case "create-password":
                return <PasswordCreation />

            case "password-created":
                return <PasswordCreated />

            default:
                return <CredentialEntry />
        }
    }

    return (
        <Container maxWidth={"sm"}>
            <Paper className="login-box">
                <div className="login-header">
                    <img src={Logo} width={"200"} alt="83bar Login Page" />
                </div>

                <div className="login-body">
                    {loginRouter()}
                </div>


            </Paper>
        </Container>
    )
}

export default LoginView