import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, FormControlLabel, Input} from "@material-ui/core";
import {MFASuccess, PasswordResetVerified, SetErrors} from "../actions";
import LoadingButton from "../utils/LoadingButton";
import Post from "../http";
import {setAccessToken, setMFAToken, setRefreshToken} from "../utils/tokenManagement";

const CodeEntry = () => {
    const [securityCode, setSecurityCode] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [rememberMe, setRememberMe] = useState(true)
    const chosenMethod = useSelector(state => state.user.chosenDeliveryMethod)
    const userID = useSelector(state => state.user.user_id)
    const loginFlow = useSelector( state => state.flow)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const changeSecurityCode = (evt) => {
        setSecurityCode(evt.target.value)
        setButtonDisabled(evt.target.value.length === 0)
    }

    const changeRememberMe = (evt) => {
        setRememberMe(evt.target.checked)
    }

    const clickContinue = () => {
        setButtonDisabled(true)
        setIsLoading(true)

        // hit check code endpoint
        let params = {
            path: "/verify-code",
            data: {
                user_id: userID,
                code: securityCode
            }
        }
        Post(params).then(response => {
            if (response.success) {
                // code was verified, response contains tokens
                setAccessToken(response.data.access_token, response.data.access_token_expires_at)
                setRefreshToken(response.data.refresh_token, response.data.refresh_token_expires_at)
                setMFAToken(response.data.mfa_token, response.data.mfa_token_expires_at)

                switch(loginFlow) {
                    case "MFA":
                        dispatch(MFASuccess())
                        break;
                    case "password-reset":
                        dispatch(PasswordResetVerified())
                        break;
                    default:
                        dispatch(SetErrors(t("utils.generalErrors.serverError")))
                }
            } else {
                dispatch(SetErrors(t("utils.generalErrors.incorrectOTP")))
            }
        }).catch( reason => {
            dispatch(SetErrors(t("utils.generalErrors.serverError")))
        })
    }

    return (
        <div className="login-view">
            <div className="login-copy">
                {t("login.codeEntry.introText").replace("{deliveryMethod}", chosenMethod)}
            </div>

            <div className="code-entry-input">
                {t("login.codeEntry.inputLabel")}
                <div className="login-input">
                    <Input type="text"
                           autoFocus
                           value={securityCode}
                           onChange={changeSecurityCode}
                    />
                </div>

            </div>

            <div className="login-copy">
                <FormControlLabel
                    className="remember-checkbox"
                    control={<Checkbox size="small" checked={rememberMe} onChange={changeRememberMe} />}
                    label={t("login.codeEntry.rememberMeLabel")}
                />
                <span>{t("login.codeEntry.rememberMeNote")}</span>
            </div>
            <LoadingButton onClick={clickContinue} isLoading={isLoading} isDisabled={buttonDisabled} label={t("login.codeEntry.continueButtonLabel")} />
        </div>
    )
}

export default CodeEntry