import React from 'react'
import {Button, CircularProgress} from "@material-ui/core";

const LoadingButton = ({label, isDisabled, isLoading, onClick}) => {

    return (
        <Button className="full-width"
                color="primary"
                onClick={onClick}
                variant="contained"
                disabled={isDisabled}
        >
            {label}  {isLoading && <CircularProgress className="loading-icon" size={"1rem"}/>}
        </Button>
    )
}

export default LoadingButton