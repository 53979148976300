import { createMuiTheme} from "@material-ui/core";

const themeConstants = {
    colors: {
        primary: "#0D406E",
        primaryLight: "rgba(13,65,110, 0.5)",
        secondary: '#18B9B1',
        action: "#F06362",
        text: {
            primary: '#373A3C',
            contact: '#383B3D'
        },
    },

}
const theme = createMuiTheme({
    palette: {
        primary: {
            main: themeConstants.colors.primary,
            light: themeConstants.colors.primaryLight
        },
        secondary: {
            main: themeConstants.colors.secondary
        },
        text: {
            primary: themeConstants.colors.text.primary
        }
    },
    typography: {
        h2: {
            fontWeight: 700,
            fontSize: "1.5rem",
            margin: "1rem 0",
        },
    },
    colors: themeConstants.colors,
    overrides: {
        MuiStepper: {
            root: {
                backgroundColor: "#fafafa",
                padding: "0 0 2rem 0",
                marginLeft: "-1rem",
                marginRight: "-1rem"
            }
        },
        MuiStepConnector: {
            root: {
                '& $line': {
                    borderColor: "#F5C0BF"
                }
            },
            active: {
                '& $line': {
                    borderColor: themeConstants.colors.primary
                }
            },

            completed: {
                '& $line': {
                    borderColor: themeConstants.colors.primary
                }
            },
        },
        MuiStepLabel: {
            labelContainer: {
                '& $alternativeLabel': {
                    marginTop: "4px"
                }
            }
        }
    }
})
export default theme

