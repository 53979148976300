import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import './index.css'
import App from './App'
import store from './store'
import Unauthorized from "./Unauthorized"
import {CssBaseline} from "@material-ui/core"
import {CookiesProvider} from "react-cookie"
import {ThemeProvider} from "@material-ui/core/styles"
import theme from "./theme"
import './i18n'

// load configuration data based on the URL the app is running on
const host = window.location.host.indexOf(":") > 0 ? window.location.host.substr(0, window.location.host.indexOf(":")) : window.location.host
fetch(window.location.protocol + "//" + window.location.host + "/data/" + host + '.json')
    .then(response => response.json())
    .then((responseJson) => {
        store.dispatch({type: 'CONFIGURE', payload: responseJson})
        ReactDOM.render(
            <ThemeProvider theme={theme}>
                <CookiesProvider>
                    <CssBaseline />
                    <Provider store={store}>
                        <App/>
                    </Provider>
                </CookiesProvider>
            </ThemeProvider>,
            document.getElementById('83Bar-Login')
        )
    })
    .catch((error) => {
        //console.error(error)
        ReactDOM.render(<Unauthorized />, document.getElementById('83Bar-Login'))

    })