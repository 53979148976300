import React from 'react'
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import {SetLoginView} from "../actions";
import {useDispatch} from "react-redux";

const PasswordCreated = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const clickLogin = () => {
        dispatch(SetLoginView("credential"))
    }

    return (
        <div className="login-view">
            <div className="login-copy">
                {t("login.createPassword.successMessage")}
            </div>

            <Button className="full-width"
                    color="primary"
                    variant="contained"
                    onClick={clickLogin}
            >{t("login.createPassword.successLabel")}</Button>
        </div>
    )
}

export default PasswordCreated